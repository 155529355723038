import React, { useState } from "react";
import { Field, Label } from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import { Button } from "../../catalyst/button";
import { useSignInEmailPassword } from "@nhost/react";
import { Heading } from "../../catalyst/heading";
import { Text } from "../../catalyst/text";
import { AuthResult } from ".";

type SignInProps = {
  setShowSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  setAuthResult?: React.Dispatch<React.SetStateAction<AuthResult | undefined>>;
};

export function SignIn({ setShowSignUp, setAuthResult }: SignInProps) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { signInEmailPassword } = useSignInEmailPassword();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleSubmitClick = () => {
    (async function () {
      if (email === "" || password === "") {
        console.log("The 'email' or 'password' field is blank.");
        return;
      }
      const result = await signInEmailPassword(email, password);
      if (!result.accessToken || !result.refreshToken || !result.user) {
        throw new Error("There was a problem while attempting to log in.");
      }
      if (setAuthResult) {
        setAuthResult({
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
          user: result.user,
        });
      }
    })();
  };

  const handleSignUpClick = () => {
    setShowSignUp(true);
  };

  return (
    <div>
      <Heading>Sign in</Heading>
      <Field className="mt-4">
        <Label>Email</Label>
        <Input name="email" onChange={handleEmailChange} />
      </Field>
      <Field className="mt-4">
        <Label>Password</Label>
        <Input
          type="password"
          name="password"
          onChange={handlePasswordChange}
        />
      </Field>
      <Button
        outline
        onClick={handleSubmitClick}
        className="mt-4 hover:cursor-pointer"
      >
        Submit
      </Button>
      <Text className="mt-4 ml-1">
        Don't have an account?{" "}
        <span
          onClick={handleSignUpClick}
          className="inline hover:cursor-pointer italic"
        >
          Sign up
        </span>
      </Text>
    </div>
  );
}
