import React, { useState } from "react";
import { SignUp } from "./SignUp";
import { SignIn } from "./SignIn";
import { NhostClient, NhostProvider, User } from "@nhost/react";

export interface AuthenticationProps {
  nhost: NhostClient;
  setAuthResult?: React.Dispatch<React.SetStateAction<AuthResult | undefined>>;
}

export interface AuthResult {
  accessToken: string;
  refreshToken: string;
  user: User;
}

export function Authentication({ nhost, setAuthResult }: AuthenticationProps) {
  const [showSignUp, setShowSignUp] = useState<boolean>(false);

  return (
    <NhostProvider nhost={nhost}>
      {showSignUp ? (
        <SignUp setAuthResult={setAuthResult} />
      ) : (
        <SignIn setShowSignUp={setShowSignUp} setAuthResult={setAuthResult} />
      )}
    </NhostProvider>
  );
}
