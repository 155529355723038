import React, { useState } from "react";
import { useSignUpEmailPassword, useProviderLink } from "@nhost/react";
import { Field, Label } from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import { Button } from "../../catalyst/button";
import { Heading } from "../../catalyst/heading";
import { Text } from "../../catalyst/text";
import { AuthResult } from ".";

type SignUpProps = {
  setAuthResult?: React.Dispatch<React.SetStateAction<AuthResult | undefined>>;
};
export function SignUp({ setAuthResult }: SignUpProps) {
  const [showSuccessfulSignup, setShowSuccessfulSignup] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { signUpEmailPassword } = useSignUpEmailPassword();
  const { github } = useProviderLink();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmitClick = () => {
    (async function () {
      if (email === "" || password === "") {
        console.log("The 'email' or 'password' field is blank.");
        return;
      }
      const result = await signUpEmailPassword(email, password);
      if (result.isError) {
        alert(
          `There was a problem signing up. Error: ${result.error!.message}`
        );
        throw result.error;
      } else if (result.needsEmailVerification) {
        setShowSuccessfulSignup(true);
      }
    })();
  };

  const handleGithubClick = () => {
    window.open(github);
  };

  return showSuccessfulSignup ? (
    <Heading>
      Success! Go to your email address for verification and then reload the
      page to sign in!
    </Heading>
  ) : (
    <div>
      <Heading>Sign up</Heading>
      <Field className="mt-4">
        <Label>Email</Label>
        <Input name="email" onChange={handleEmailChange} />
      </Field>
      <Field className="mt-4">
        <Label>Password</Label>
        <Input
          type="password"
          name="password"
          onChange={handlePasswordChange}
        />
      </Field>
      <Button
        outline
        onClick={handleSubmitClick}
        className="hover:cursor-pointer mt-4 block"
      >
        Submit
      </Button>
      <Text
        onClick={handleGithubClick}
        className="ml-1 mt-4 hover:cursor-pointer italic"
      >
        Sign up using GitHub
      </Text>
    </div>
  );
}
